import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { getDiscountPrice, getFirstPrice, numberWithCommas2, showCurrencycodeSymbol } from "../../../helpers/product";
import { useProductPriceToShow } from "../state/useProductPriceToShow";
import { useThemeSettingsData } from "../../../layouts/state/useThemeSettingsData";
import { AuthContext } from "../../../common/Auth";

const ProductPriceUni = ({
                          productPrice,
                          usePointsAsCurrency,
                          showSellPrice,
                          showSell,
                          finalProductDiscPrice,
                          finalDiscountedPrice,
                          productDiscount,
                          finalProductPrice,
                          currency,
                          mainClass,
                          decimalsToShow,
                      })=>{
    const discountedPrice = getDiscountPrice(productPrice, productDiscount);
    const {  themeSettingsData } = useContext(AuthContext)
    const {
        productSettings,
    } = useThemeSettingsData({ themeSettingsData })

    const {
        getPointsFromValue,
        getPointsValue
    } = useProductPriceToShow({ productSettings})

    return <div className={`antera-product-price-container ${mainClass}`}>
        {
            usePointsAsCurrency ?
                <span className="antera-product-price-text">{ getPointsValue(finalProductPrice) }</span> : <>
                    { discountedPrice !== null ? (
                        <Fragment>
                            <span className="antera-product-price-text">
                                { showCurrencycodeSymbol(currency) + numberWithCommas2(finalDiscountedPrice, decimalsToShow) }
                            </span>{ " " }
                            <span className="antera-product-price-sale-text old">
                                { showCurrencycodeSymbol(currency) + numberWithCommas2(finalProductPrice, decimalsToShow) }
                            </span>
                        </Fragment>
                    ) : (
                        <>
                            <span className={ `antera-product-price-text ${ showSellPrice ? 'breakable-price' : '' }` }>
                                { showCurrencycodeSymbol(currency) + numberWithCommas2(showSellPrice ? finalProductPrice : finalProductDiscPrice, decimalsToShow) } { " " }
                            </span>
                            { showSellPrice && <span className="antera-product-price-sale-text">
                                { (showCurrencycodeSymbol(currency) + numberWithCommas2(finalProductDiscPrice, decimalsToShow)) }
                            </span>
                            }
                        </>
                    ) }
                </>
        }
    </div>
};

ProductPriceUni.propTypes = {
    productPrice: PropTypes.string,
    usePointsAsCurrency: PropTypes.bool,
    showSellPrice: PropTypes.bool,
    finalProductDiscPrice: PropTypes.number,
    finalDiscountedPrice: PropTypes.number,
    productDiscount: PropTypes.number,
    finalProductPrice: PropTypes.string,
    currency: PropTypes.string,
    mainClass: PropTypes.string,

};

export default ProductPriceUni;
